import styled from "styled-components";

export const StyledWrapper = styled.div`
         align-items: center;
         min-height: 100vh;
         display: flex;
         flex-direction: column;
         justify-content: center;
         .box {
           margin-top: 100px;
           margin-left: auto;
           margin-right: auto;
           max-width: 450px;
           padding: 30px 0;
           width: 90%;
           background: #ffffff;
           box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
           border-radius: 5px;
           border: 0.5px solid #b4b4b4;
           .logo {
             display: flex;
             justify-content: center;
             padding-bottom: 10px;
             img {
               width: 50px;
             }
           }
           form {
             padding: 10px;
             .form-submit {
               display: flex;
               margin-top: 30px !important;
               margin: 14px;
               padding: 15px 10px;
               background: #ccc9ff;
               border-radius: 5px;
               input {
                 background: #ccc9ff;
                 width: 100%;
                 border: 0;
                 color: #3329e0;
                 font: inherit;
                 margin: 0;
                 outline: 0;
                 padding: 0;
               }
               input[type="submit"] {
                 cursor: pointer;
               }
             }
             .red-text {
               color: red;
               font-size: 13px;
               padding-left:5%;
             }
             .span {
               padding: 0 15px;
               span {
                 color: #3329e0;
               }
             }
             .form-field {
               display: flex;
               margin-top: 40px !important;
               margin: 15px;
               padding: 15px 10px;
               background: #ffffff;
               border-radius: 10px;
               border: 0.5px solid #b4b4b4;
               box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
               border-radius: 6px;
               input {
                 width: 100%;
                 background-image: none;
                 border: 0;
                 color: inherit;
                 font: inherit;
                 margin: 0;
                 outline: 0;
                 padding: 0;
                 -webkit-transition: background-color 0.3s;
                 transition: background-color 0.3s;
               }
               input[type="submit"] {
                 cursor: pointer;
               }
             }
           }
         }
       `;