import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Link, navigate } from 'gatsby';
import { Formik, Form, Field } from 'formik';
import { StyledWrapper } from '../../../containers/SignUp/SignUp.styles';
import firebase from 'gatsby-plugin-firebase';
import NotificationSystem from 'react-notification-system';
import logo from '../../../components/images/logo.png';
import loading from '../../../components/images/loading.gif';

const SignUp = ({ location }) => {
  const [password, setPassword] = useState('');

  const [authError, setAuthError] = useState(null);

  const [user, setUser] = useState(null);

  const [buttonState, setButtonState] = useState(false);

  const notificationSystem = useRef();

  const addNotification = ({ title, message, level }) => {
    const notification = notificationSystem.current;

    notification.addNotification({
      title,
      message,
      level,
    });
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      }
    });
  }, []);

  //fields validation
  const validateEmail = value => {
    let error;

    if (!value) {
      error = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  };

  const validatePassword = value => {
    let error;

    setPassword(value);
    if (!value) {
      error = 'Passoword is required';
    } else if (value.length < 8) {
      error = 'Passoword should be atleast 8 characters';
    }
    return error;
  };

  const validateConfirmPassword = value => {
    let error;

    if (!value) {
      error = 'Confirm Passoword is required';
    } else if (value.length < 8) {
      error = 'Confirm Passoword should be atleast 8 characters';
    } else if (value !== password) {
      error = 'Password does not match';
    }
    return error;
  };

  const validateName = value => {
    let error;

    if (!value) {
      error = 'Name is required';
    } else if (value.length < 4) {
      error = 'Name should be atleast 4 characters';
    }
    return error;
  };

  const createUser = values => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(values.email, values.password)
      .catch(error => {
        // Handle Errors here.
        let errorMessage = error.message;
        addNotification({
          title: 'Error',
          message: errorMessage,
          level: 'error',
        });

        setAuthError(errorMessage);
        // ...
      })
      .then(auth => {
        setButtonState(false);
        firebase
          .firestore()
          .collection('users')
          .doc(auth.user.uid)
          .set({
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            disabled: false,
            email: values.email,
            name: values.name,
            framerPlan: 'free',
            currentPlan: 'free',
            noOfExports: 20,
            aiRequestCount: 15,
            projects: [],
            isActive: false,
            signedUp: "devwares"
          });

        let actionCodeSettings = {
          url: 'https://www.devwares.com/app2/signin?email=' + firebase.auth().currentUser.email,
        };

        addNotification({
          title: 'Account Created',
          message: 'Your now have an Account at Devwares',
          level: 'success',
        });

        auth.user
          .sendEmailVerification(actionCodeSettings)
          .then(function() {
            if (location.search == '?q=WindFrame') {
              navigate('https://windframe.devwares.com');
            } else {
              navigate('/app/confirm');
            }
          })
          .catch(error => {
            console.log(error);
          });
      });
  };

  return (
    <Fragment>
      <StyledWrapper>
        <NotificationSystem ref={notificationSystem} />
        <div className="box mb-5">
          <Link to="/">
            <div className="logo">
              <img src={logo} alt="devwares" />
            </div>
          </Link>
          <Formik
            initialValues={{
              name: '',
              email: '',
              password: '',
              confirmPassword: '',
            }}
            onSubmit={values => {
              setButtonState(true);
              // setTimeout(() => {
              //   alert(JSON.stringify(values, null, 2));
              //   setSubmitting(false);
              // }, 400);
              // dispatch(signUp(values));
              createUser(values);
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              /* and other goodies */
            }) => (
              <Form className="form login">
                <div className="form-field">
                  <Field
                    type="text"
                    name="name"
                    className="form-input"
                    placeholder="Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    validate={validateName}
                  />
                </div>
                <div className="red-text">{errors.name && touched.name && errors.name}</div>
                <div className="form-field">
                  <Field
                    type="email"
                    name="email"
                    className="form-input"
                    placeholder="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    validate={validateEmail}
                  />
                </div>
                <div className="red-text">{errors.email && touched.email && errors.email}</div>
                <div className="form-field">
                  <Field
                    type="password"
                    name="password"
                    className="form-input"
                    placeholder="Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    validate={validatePassword}
                  />
                </div>
                <div className="red-text">
                  {errors.password && touched.password && errors.password}
                </div>

                <div className="form-field">
                  <Field
                    type="password"
                    name="confirmPassword"
                    className="form-input"
                    placeholder="Confirm Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    validate={validateConfirmPassword}
                  />
                </div>
                <div className="red-text">
                  {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                </div>
                <span>
                  <span className="span" style={{ color: '#3329e0' }}>
                    <Link to="/app2/forgot-password">Forgot Password?</Link>
                  </span>
                </span>
                <div className="form-submit">
                  {buttonState && (
                    <img src={loading} alt="loading gif" style={{ margin: 'auto' }} />
                  )}
                  {!buttonState && <input type="submit" disabled={buttonState} value="Sign Up" />}
                </div>
                <div className="red-text">{authError ? <p>{authError} </p> : null}</div>
                <span className="span">
                  Already have an Account?{' '}
                  <span>
                    <Link to="/app2/signin">Sign In</Link>
                  </span>
                </span>
              </Form>
            )}
          </Formik>
        </div>
      </StyledWrapper>
    </Fragment>
  );
};

export default SignUp;